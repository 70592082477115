import axios from 'axios'
import qs from 'qs'
import config from '@/config'
import {Message, Loading} from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

class Http {
	constructor () {
    this.counterPost = 0 
    this.counterGet = 0 
		this.loadingInstance = {}
  }
	destroy () {
    if (this.counterPost > 0) {
      if (--this.counterPost === 0) {
        this.loadingInstance.close()
      }
    }
    if (this.counterGet > 0) {
      if (--this.counterGet === 0) {
        NProgress.done()
      }
    }
  }
  assign (option) {
    const request = config.request[process.env.NODE_ENV]
    option = Object.assign({
      baseURL: `${request.protocol}${option.node === undefined ? request.node.api : request.node[node]}.${config.domain}`,
      loading: true
    }, option)
    const token = {
      token: sessionStorage.getItem(config.tokenName)
    }
    if (option.method === `POST`) {
      option.headers = {}
      option.headers['Content-Type'] = `application/x-www-form-urlencoded`
      option.data = Object.assign(option.data === undefined ? {} : option.data, token)
      // Object.keys(option.data).forEach(k => {
      //   if (option.data[k] === null || (isArray(option.data[k]) && option.data[k].length === 0)) {
      //     option.data[k] = ""
      //   }
      // })
      option.data = qs.stringify(option.data)
    } else {
      option.params = Object.assign(option.params === undefined ? {} : option.params, token)
    }
    return option
  }
  request(option) {
    // 合并选项
    option = this.assign(option)
    
    // loading
    if (option.loading) {
      if (option.method === `POST`) {
        if (this.counterPost === 0) {
          this.loadingInstance = Loading.service({
            fullscreen: true
          })
        }
        this.counterPost++
      } else {
        if (this.counterGet === 0) {
          NProgress.start()
        }
        this.counterGet++
      } 
    }

    // 请求
    axios(option).then(res => {
      const code = res.data.code
      const msg = res.data.msg
      const data= res.data.data
      let done = () => {}
      switch(code) {
  			case -1:
					done = () => {
						sessionStorage.removeItem(config.tokenName)
						sessionStorage.removeItem(config.ruleName)
						location.href = config.defaultURL
					}
        break
        
  			// case 0:
  			// done = option.error === undefined ? () => {} : option.error
        // break
        
  			case 1:
  				done = option.success === undefined ? () => {} : option.success
  			break

				default:
					const funcName = code == 0 ? "error" : `error${code}`
					done = option[funcName] === undefined ? () => {} : option[funcName]
      }

  		if (msg === "" || msg === null) {
        done(data)
        this.destroy()
  		} else {
				Message({
					type: code === 1 ? `success` : `error`,
					message: msg,
					duration: 1800,
					onClose: () => {
            done(data)
            this.destroy()
					}
				})
  		}
    }).catch(error => {
			this.destroy()
			Message.error(error.message)
		})
  }
}

export default new Http