import Container from '@/views/container'
export default [
  {
    path: `/`,
    component: () => import('@/views/login'),
    meta: {
      hideInMenu: true
    }
  },
  {
    path: `/reset`,
    component: () => import('@/views/reset'),
    meta: {
      hideInMenu: true
    }
  },
  {
    path: `/home`,
    component: Container,
    meta: {
      title: `首页`,
      icon: `el-icon-cloudy`,
      index: `10`
    },
    children: [
      {
        path: "",
        component: () => import('@/views/home')
      }
    ]
  },
  {
    path: `/auth`,
    component: Container,
    meta: {
      title: `权限`,
      icon: `el-icon-key`,
      index: `20`
    },
    children: [
      {
        path: `api`,
        component: () => import('@/views/auth/api'),
        meta: {
          title: `API`,
          index: `20-1`
        }
      },
      {
        path: `role`,
        component: () => import('@/views/auth/role'),
        meta: {
          title: `角色`,
          index: `20-2`
        }
      }
    ]
  },
  {
    path: `/org`,
    component: Container,
    meta: {
      title: `组织架构`,
      icon: `el-icon-share`,
      index: `13`
    },
    children: [
      {
        path: `branch`,
        component: () => import('@/views/org/branch'),
        meta: {
          title: `部门`,
          index: `13-1`
        }
      },
      {
        path: `staff`,
        component: () => import('@/views/org/staff'),
        meta: {
          title: `员工`,
          index: `13-3`
        }
      },
      {
        path: `wx`,
        component: () => import('@/views/org/wx'),
        meta: {
          title: `微信号`,
          index: `13-2`
        }
      }
    ]
  },
  {
    path: `/goods`,
    component: Container,
    meta: {
      title: `商品`,
      icon: `el-icon-goods`,
      index: `11`
    },
    children: [
      {
        path: `goods`,
        component: () => import('@/views/goods'),
        meta: {
          title: `商品`,
          index: `11-5`
        }
      },
      {
        path: `supplier`,
        component: () => import('@/views/goods/supplier'),
        meta: {
          title: `供应商`,
          index: `11-1`
        }
      },
      {
        path: `erp`,
        component: () => import('@/views/goods/erp'),
        meta: {
          title: `ERP`,
          index: `11-2`
        }
      },
      {
        path: `group`,
        component: () => import('@/views/goods/group'),
        meta: {
          title: `分组`,
          index: `11-3`
        }
      }
    ]
  },
  {
    path: `/customer`,
    component: Container,
    meta: {
      title: `客户`,
      icon: `el-icon-user`,
      index: `23`
    },
    children: [
      {
        path: `customer`,
        component: () => import('@/views/customer'),
        meta: {
          title: `客户`,
          index: `23-1`
        }
      },
      {
        path: `auction`,
        component: () => import('@/views/customer/auction'),
        meta: {
          title: `客户分配`,
          index: `23-2`
        }
      },
			{
				path: 'merge',
				component: () => import('@/views/customer/merge'),
				meta: {
					title: '客户合并',
					index: '23-7'
				}
			},
      {
        path: `channel`,
        component: () => import('@/views/customer/channel'),
        meta: {
          title: `渠道`,
          index: `23-3`
        }
      },
      {
        path: `tag`,
        component: () => import('@/views/customer/tag'),
        meta: {
          title: `标签`,
          index: `23-4`
        }
      },
			{
				path: "sop",
				component: () => import('@/views/customer/sop'),
				meta: {
					title: "SOP",
					index: "23-6"
				}
			},
      {
        path: `user`,
        component: () => import('@/views/customer/user'),
        meta: {
          title: `小程序用户`,
          index: `23-5`
        }
      }
    ]
  },
  {
    path: `/trade`,
    component: Container,
    meta: {
      title: `交易`,
      icon: `el-icon-wallet`,
      index: `14`
    },
    children: [
      {
        path: `orderChild`,
        component: () => import('@/views/trade/orderChild'),
        meta: {
          title: `订单`,
          index: `14-1`          
        }
      },
      {
        path: `refund`,
        component: () => import('@/views/trade/refund'),
        meta: {
          title: `售后`,
          index: `14-2`
        }
      },
			{
				path: 'trackContract',
				component: () => import('@/views/trade/trackContract'),
				meta: {
					title: "陪伴合同",
					index: "14-3"
				}
			},
			{
				path: 'scoreOrder',
				component: () => import('@/views/trade/scoreOrder'),
				meta: {
					title: '积分兑换',
					index: '14-4'
				}
			}
    ]
  },
  {
    path: `/finance`,
    component: Container,
    meta: {
      title: `财务`,
      icon: `el-icon-money`,
      index: `24`
    },
    children: [
      {
        path: 'orderPay',
        component: () => import('@/views/finance/order-pay'),
        meta: {
          title: `销售收入`,
          index: `24-1`
        }
      },
      {
        path: 'branchBill',
        component: () => import('@/views/finance/branch-bill'),
        meta: {
          title: `部门账单`,
          index: `24-2`
        }
      },
      {
        path: 'vipBill',
        component: () => import('@/views/finance/vip-bill'),
        meta: {
          title: `VIP账单`,
          index: `24-5`
        }
      },
		{
			path: 'vipService',
			component:()=> import('@/views/finance/vip-service'),
			meta: {
				title: "VIP充值卡",
				index: "27-5"
			}
		}
    ]
  },
  {
    path: `/store`,
    component: Container,
    meta: {
      title: `店铺`,
      icon: `el-icon-school`,
      index: `12`
    },
    children: [
      {
        path: `store`,
        component: () => import('@/views/store/store/form'),
        meta: {
          title: `店铺设置`,
          index: `12-1`
        }
      },
      {
        path: `swiper`,
        component: () => import('@/views/store/swiper'),
        meta: {
          title: `轮播`,
          index: `12-2`
        }
      },
      {
        path: `special`,
        component: () => import('@/views/store/special'),
        meta: {
          title: `专题`,
          index: `12-3`
        }
      }
    ]
  },
  {
    path: `/promote`,
    component: Container,
    meta: {
      title: `促销`,
      icon: `el-icon-sell`,
      index: `15`
    },
    children: [
      {
        path: `groupbuy`,
        component: () => import('@/views/promote/groupbuy'),
        meta: {
          title: `团购`,
          index: `15-1`
        }
      },
      {
        path: `coupon`,
        component: () => import('@/views/promote/coupon'),
        meta: {
          title: `优惠券`,
          index: `15-2`
        }
      },
			{
				path: `shoppinglist`,
				component: () => import('@/views/promote/shoppinglist'),
				meta: {
					title: `购物清单`,
					index: `15-3`
				}
			}
    ]
  },
  {
    path: `/marketing`,
    component: Container,
    meta: {
      title: `营销`,
      icon: `el-icon-present`,
      index: `27`
    },
    children: [
      {
        path: "blogger",
        component: () => import('@/views/marketing/blogger'),
        meta: {
          title: "博主",
          index: "27-1"
        }
      },
      {
        path: "cooperate",
        component: () => import('@/views/marketing/cooperate'),
        meta: {
          title: "合作",
          index: "27-2"
        }
      },
			{
				path: "activity",
				component: () => import('@/views/marketing/activity'),
				meta: {
					title: "活动排期",
					index: "27-3"
				}
			},
			{
				path: "track",
				component: () => import('@/views/marketing/track'),
				meta: {
					title: '陪伴计划',
					index: '27-4'
				}
			},
			{
				path: "sign",
				component: () => import('@/views/marketing/sign'),
				meta: {
					title: "签到",
					index: "27-5"
				}
			},
			{
				path: "scoreGoods",
				component: () => import('@/views/marketing/scoreGoods'),
				meta: {
					title: "积分商品",
					index: "27-6"
				}
			}
    ]
  },
	{
		path: "/content",
		component: Container,
		meta: {
			title: "内容",
			icon: "el-icon-tickets",
			index: "30"
		},
		children: [
			{
				path: "moment",
				component: ()=> import('@/views/content/moment'),
				meta: {
					title: "朋友圈",
					index: "30-0"
				}
			},
			{
				path: "recommend",
				component: ()=> import('@/views/content/recommend'),
				meta: {
					title: "种草排期",
					index: "30-1"
				}
			},
			{
				path: "groupbuyScript",
				component: ()=> import('@/views/content/groupbuyScript'),
				meta: {
					title: "开复团",
					index: "30-4"
				}
			},
			{
				path: "faq",
				component: ()=> import('@/views/content/faq'),
				meta: {
					title: "百问百答",
					index: "30-2"
				}
			},
			{
				path: `banword`,
				component: () => import('@/views/content/banword'),
				meta: {
          title: `违禁词`,
          index: `30-3`
				}
			}
		]
	},
  {
    path: `/dashboard`,
    component: Container,
    meta: {
      title: `数据`,
      icon: `el-icon-pie-chart`,
      index: `17`
    },
    children: [
			{
				path: "global",
				component: () => import('@/views/dashboard/global'),
				meta: {
					title: "概况",
					index: "17-0"
				}
			},
      {
        path: `cart`,
        component: () => import('@/views/dashboard/cart'),
        meta: {
          title: `购物车`,
          index: `17-1`
        }
      },
      {
        path: `newCustomer`,
        component: () => import('@/views/dashboard/newCustomer'),
        meta: {
          title: `新客`,
          index: `17-2`          
        }
      },
      {
        path: `rebuy`,
        component: () => import('@/views/dashboard/rebuy'),
        meta: {
          title: `复购`,
          index: `17-3`          
        }
      },
      {
        path: `shopping`,
        component: () => import('@/views/dashboard/shopping'),
        meta: {
          title: `购物`,
          index: `17-4`          
        }
      }
    ]
  },
  {
    path: `/app`,
    component: Container,
    meta: {
      title: `应用`,
      icon: `el-icon-news`,
      index: `25`
    },
    children: [
      {
        path: "",
        component: () => import('@/views/app')
      }
    ]
  }
]