import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// UI框架
import ElementUI from 'element-ui'
import './style/theme/index.css'
Vue.use(ElementUI)

// 自定义样式
import './style/base.less'

// 配置
import config from './config'
Vue.prototype.$config = config

// 服务器交互
import http from './libs/http'
Vue.prototype.$http = http

// 指令
import permission from './directive/permission'
Vue.directive('permission', permission)

new Vue({
  router,
	store,
  render: h => h(App)
}).$mount('#app')
