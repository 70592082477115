import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import routesApp from './routesApp'
import config from '@/config'

// 路由
Vue.use(VueRouter)
routes = routes.concat(routesApp)
const router = new VueRouter({
	mode: `history`,
  routes
})

router.beforeEach((to, from, next) => {
  // app登录
  if (to.query.accessToken !== undefined) {
    sessionStorage.setItem(config.tokenName, to.query.accessToken)
  }
  next()
})

router.afterEach(to => {
	document.title = to.name === undefined ? config.systemName : to.name
  window.scrollTo(0, 0)
})

export default router
