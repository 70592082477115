<template>
  <div>

		<div class="header">
			<div class="header__logo">
				<img :src="store._icon" class="header__icon" />{{store.name}}
			</div>
			<div class="header__info">
				<div class="header__col">
					<el-dropdown>
						<span class="header__text">
							<img :src="staff._avatar" class="header__avatar" />
							{{staff.name}}<i class="el-icon-arrow-down" />
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item><el-link type="primary" @click="$router.push('/reset')">修改密码</el-link></el-dropdown-item>
							<el-dropdown-item><el-link type="primary" @click="logout">安全退出</el-link></el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
				<div class="header__col">
					<msg />
				</div>
			</div>
		</div>

    <!--container-->
    <div class="container">
      <div class="container__aside">
        <el-menu :default-active="$route.path" router unique-opened>
          <template v-for="route in routes">
            <template v-if="route.meta.hideInMenu !== true && hasLeafNode(route.children)">
              <el-submenu :index="route.meta.index" v-if="route.children[0].path != ``" :key="route.meta.index">
                <template slot="title">
                  <i :class="route.meta.icon"></i>
                  <span slot="title">{{route.meta.title}}</span>
                </template>
                <el-menu-item :index="`${route.path}/${_route.path}`" v-for="_route in route.children" :key="_route.meta.index" v-permission="_route.path"><!--v-permission="_router.meta.roles"-->
                  {{_route.meta.title}}
                </el-menu-item>
              </el-submenu>
              <template v-else>
                <el-menu-item :index="route.path" :key="route.meta.index">
                  <i :class="route.meta.icon"></i>
                  <span slot="title">{{route.meta.title}}</span>
                </el-menu-item>
              </template>
            </template>
          </template>
        </el-menu>
      </div>
      <div class="container__main">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import routes from '@/router/routes'
import {oneOf} from '@/libs/tool'
import msg from './msg'
export default {
  name: 'Container',
	components: {
		msg
	},
  data() {
    return {
      staff: {},
      store: {},
      routes
    }
  },
  mounted() {
    const self = this
    self.$http.request({
      url: `staff/staff/readme`,
      success: res => {
        self.staff = res.data
      }
    })
    self.$http.request({
      url: `staff/store/read`,
      success: res => {
        self.store = res.data
      }
    })
  },
  methods: {
    logout() {
      // 退出
      sessionStorage.removeItem(this.$config.tokenName)
      sessionStorage.removeItem(this.$config.ruleName)
      this.$router.push({path: this.$config.defaultURL})
    },
    hasLeafNode(routes) {
      const ruleSignList = JSON.parse(sessionStorage.getItem(this.$config.ruleName)) || [];
			return routes.some(route => {
        return !route.meta ? true : oneOf(route.path, ruleSignList)
			})
    }
  }
}
</script>

<style lang="less">
.header {
	height: 60px;
	padding: 0 20px;
	background-color: #2C2C2C;
	color: #FFF;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&__icon, &__avatar {
    margin-right: 8px;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    cursor: pointer
	}

	&__avatar {
		border-radius: 50%;
	}

	&__logo {
		font-size: 20px
	}

	&__info {
		display: flex;
		flex-direction: row-reverse
	}
	&__col {
		margin-left: 80px
	}
	&__col:last-child {
		margin-left: 0
	}

	&__text {
		color: #FFF
	}
}

.container {
  padding: 0 20px;
  margin: 20px 0;
  display: flex;

  &__aside {
    width: 200px;
    background-color: #fff;
    margin-right: 40px;
    flex-shrink: 0 // 固定的 不缩小
  }

  &__main {
    width: 0;
    flex: auto;
    min-height: 680px;
  }
}
</style>