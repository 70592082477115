import Vue from 'vue'
import Vuex from 'vuex'
import http from '@/libs/http'

Vue.use(Vuex)

// 创建VUEX对象
const store = new Vuex.Store({
	state: {
		chatMsgCount: 0
	},
	// getters: {
	// 	msgCount(state) {
	// 		return state.chatMsgCount
	// 	}
	// },
	mutations: {
		setChatMsgCount: (state, payload)=> {
			state.chatMsgCount = payload.chatMsgCount
		}
	},
	actions: {
		setChatMsgCountAsync: (context)=> {
			http.request({
				url: 'open/chatlog/search',
				loading: false,
				params: {operate: 'count'},
				success: res=> {
					context.commit('setChatMsgCount', {
						chatMsgCount: res.count
					})
				}
			})
		}
	}
})

export default store