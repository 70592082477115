export default [
	{
		path: "/app/tongue",
		component: ()=> import('@/views/app/tongue'),
		meta: {
			title: "肌肤检测",
			hideInMenu: true
		}
	},
	{
		path: "/app/score/jumbotron",
		component: () => import('@/views/app/score/jumbotron'),
		meta: {
			title: "业绩大屏",
			hideInMenu: true
		}
	},
  	{
		path: "/app/score/ranking",
    	component: () => import('@/views/app/score/ranking'),
    	meta: {
			title: "员工业绩",
      		hideInMenu: true
    	}
  	},
  {
		path: "/app/skin",
    component: () => import('@/views/app/skin'),
    meta: {
			title: "肌肤检测",
      hideInMenu: true
    }
  },
	{
		path: "/app/chatlog",
		component: () => import('@/views/app/chatlog'),
		meta: {
			title: "聊天监控",
			hideInMenu: true
		}
	},
	{
		path: "/app/activity",
		component: () => import('@/views/marketing/activity/search'),
    meta: {
			title: "活动排期",
      hideInMenu: true
    }
	},
	{
		path: "/app/recommend",
		component: () => import('@/views/content/recommend/search'),
		meta: {
			title: "种草排期",
			hideInMenu: true
		}
	},
	{
		path: "/app/dashboard",
		component: () => import('@/views/home'),
		meta: {
			title: "员工仪表盘",
			hideInMenu: true
		}
	},
	{
		path: "/app/faq",
		component: () => import('@/views/content/faq/search'),
		meta: {
			title: "百问百答",
			hideInMenu: true
		}
	},
	{
		path: "/app/moment-form",
		component: ()=> import('@/views/content/moment/form-iframe'),
		meta: {
			title: "添加/编辑朋友圈",
			hideInMenu: true
		}
	},
	{
		path: "/app/order-form",
		component: ()=> import('@/views/trade/order/form-iframe'),
		meta: {
			title: "添加订单",
			hideInMenu: true
		}
	}
];