import {oneOf} from '@/libs/tool'
import config from '@/config'
export default {
	inserted(el, binding, vnode) {
		const ruleSign = binding.value
		const ruleSignList = JSON.parse(sessionStorage.getItem(config.ruleName)) || []
		if (ruleSign && !oneOf(ruleSign, ruleSignList)) {
			el.parentNode && el.parentNode.removeChild(el)
		}
	}
}