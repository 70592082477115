<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
<style lang="less">
body {
	background-color: #FAFAFA;
	font-family: 'Microsoft YaHei', tahoma, arial, 'Hiragino Sans GB', sans-serif
}
</style>