/**
 * @description 浏览器名称
 * @return {string}
 */
export const getExplorer = () => {
  const ua = window.navigator.userAgent
  const isExplorer = (exp) => {
    return ua.indexOf(exp) > -1
  }
  if (isExplorer('MSIE')) return 'IE'
  else if (isExplorer('Firefox')) return 'Firefox'
  else if (isExplorer('Chrome')) return 'Chrome'
  else if (isExplorer('Opera')) return 'Opera'
  else if (isExplorer('Safari')) return 'Safari'
}

/**
 * @description 如果传入的数值小于10，即位数只有1位，则在前面补充0
 * @param {number} num
 * @return {string}
 */
export const getHandledValue = (num) => {
  return (num < 10 ? '0' + num : num).toString()
}

/**
 * @description 值是否在数组中
 * @param {string|number} value
 * @param {array} valueList
 * @return {boolean}
 */
export const oneOf = (value, valueList) => {
  for (let i = 0; i < valueList.length; i++) {
    if (value === valueList[i]) {
      return true
    }
  }
  return false
}

/**
 * @description 2个数组交集
 * @param {array} arr1
 * @param {array} arr2
 * @return {array}
 */
export const getIntersection = (arr1, arr2) => {
  let len = Math.min(arr1.length, arr2.length)
  let i = -1
  let res = []
  while (++i < len) {
    const item = arr2[i]
    if (arr1.indexOf(item) > -1) res.push(item)
  }
  return res
}

/**
 * @description 2个数组并集
 * @param {array} arr1
 * @param {array} arr2
 * @return {array}
 */
export const getUnion = (arr1, arr2) => {
  return Array.from(new Set([...arr1, ...arr2]))
}

/**
 * @description 判断两个对象是否相等，这两个对象的值只能是数字或字符串
 * @param {object} obj1
 * @param {object} obj2
 * @return {boolean}
 */
export const objEqual = (obj1, obj2) => {
  const keysArr1 = Object.keys(obj1)
  const keysArr2 = Object.keys(obj2)
  if (keysArr1.length !== keysArr2.length) return false
  else if (keysArr1.length === 0 && keysArr2.length === 0) return true
  else return !keysArr1.some(key => obj1[key] != obj2[key])
}


/**
* @description 替换obj1存在的值 /a数组 /m混合
* @param {object} obj1
* @param {object} obj2
* @return {object}
*/
export const objReplace = (obj1, obj2) => {
  for (let key in obj1) {
    if (obj2[`_${key}/a`] !== undefined) {// 数组
      obj1[key] = obj2[`_${key}/a`]
    } else if (obj2[`_${key}/d`] !== undefined) {// 数字
      obj1[key] = obj2[`_${key}/d`]
    } else if (obj2[`_${key}/s`] !== undefined) {// 字符串
      obj1[key] = obj2[`_${key}/s`]
    } else if (obj2[`_${key}/m`] !== undefined) {// 混合
      obj1[key] = obj2[`_${key}/m`]
    } else if (obj2[key] !== undefined) {
      obj1[key] = obj2[key]
    }
  }
  return obj1;
}


/**
 * @description 是否为数组
 * @param {Array} arr
 * @return {Boolean}
 */
export const isArray = (arr) => {
  return typeof arr === 'object' && arr.constructor === Array
}

/**
 * @description 获取唯一ID
 * @return {string}
 */
export const uniqid = () => {
  let s = []
  var hexDigits = "0123456789abcdef"
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = "4"
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
  s[8] = s[13] = s[18] = s[23] = "-"
  return s.join("")
}

/**
 * @description 当前月
 * @return {string}
 */
export const getCurrentMonth = () => {
  const now = new Date();
  const year = now.getFullYear()
  let month = now.getMonth() + 1
  if (month >= 1 && month <= 9) {
    month = `0${month}`
  }
  return `${year}-${month}`
}

/**
 * @description 当日
 * @return {string}
 */
export const getCurrentDate = () => {
	const now = new Date()
	const year = now.getFullYear()
	let month = now.getMonth() + 1
	if (month >= 1 && month <= 9) {
		month = `0${month}`
	}
	let date = now.getDate()
	if (date >= 1 && date <= 9) {
		date = `0${date}`
	}
	return `${year}-${month}-${date}`
}

/**
 * @description 获取周时段
 * @return {array}
 */
export const getCurrentWeekCycle = ()=> {
	const currentDate = new Date()
	const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay() + 2)
	const endDate = new Date(startDate)
	endDate.setDate(endDate.getDate() + 6)
  const startFormattedDate = startDate.toISOString().slice(0, 10)
  const endFormattedDate = endDate.toISOString().slice(0, 10)
	return [startFormattedDate, endFormattedDate];
}

/**
 * @description 获取月时段
 * @return {array}
 */
export const getCurrentMonthCycle = ()=> {
	const currentDate = new Date()
	const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 2)
	const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
	const startFormattedDate = startDate.toISOString().slice(0, 10)
  const endFormattedDate = endDate.toISOString().slice(0, 10)
  return [startFormattedDate, endFormattedDate]
}

/**
 * @description 获取季度时段
 * @return {array}
 */
export const getCurrentQuarterCycle = ()=> {
	const currentDate = new Date()
	const currentYear = currentDate.getFullYear()
	const currentMonth = currentDate.getMonth()
	const quarter = Math.ceil((currentMonth + 1) / 3)

	if (quarter == 1) {
		return [`${currentYear}-01-01`, `${currentYear}-03-31`]
	} else if (quarter == 2) {
		return [`${currentYear}-04-01`, `${currentYear}-06-30`]
	} else if (quarter == 3) {
		return [`${currentYear}-07-01`, `${currentYear}-09-30`]
	} else {
		return [`${currentYear}-10-01`, `${currentYear}-12-31`]
	}
}

/**
 * @description 获取年度时段
 * @return {array}
 */
export const getCurrentYearCycle = ()=> {
	const currentDate = new Date()
	const currentYear = currentDate.getFullYear()
	return [`${currentYear}-01-01`, `${currentYear}-12-31`]
}


