<template>
	<div>
		<el-link @click="$router.push('/app/chatlog')" :underline="false" style="color: #FFF" v-permission="'open.chatlog'">
			聊天监控<el-badge :value="$store.state.chatMsgCount" :max="99" :hidden="$store.state.chatMsgCount == 0 ? true : false" style="margin-left: 8px" />
		</el-link>
	</div>
</template>
<script>
export default {
	mounted() {
		const ruleSignList = JSON.parse(sessionStorage.getItem(this.$config.ruleName)) || [];
		for (let i = 0; i < ruleSignList.length; i++) {
			if (ruleSignList[i] == 'open.chatlog') {
				this.$store.dispatch('setChatMsgCountAsync')
				return true
			}
		}
	}
}
</script>