const domainApi = `moxiaohong.com`
const domainCdn = `mxh666.com`

export default {
  // 系统名称
  systemName: `灯塔私域系统`,

  // 令牌存储名
  tokenName: `TOKEN`,

  // 规则存储名
  ruleName: `RULESIGN`,

  // 登录前主页
  defaultURL: `/`,

  // 主页
  homeURL: `/home`,

  // 上传
  upload: {
    qiniu: {
      putExtra: {
        mimeType: null
      },
      config: {
        useCdnDomain: true,
        region: `z2`
      }
    }
  },

  cdn: {
    image: `https://image.${domainCdn}`
  },

  // 请求
  request: {
		production: {
      protocol: "https://",
			node: {
				api: "scrm.api"
			}
		},
		development: {
			protocol: "http://",
			node: {
				api: "scrmdev.api"
			}
		}
  },

  // 域名
  domain: domainApi
}